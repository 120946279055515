import { Place as PlaceType, MAX_SEARCH_HISTORY_LENGTH } from 'stores/SearchHistoryStore'

export type ModifiedGeolocation = {
    lat: number
    lng: number
}

const EARTH_RADIUS = 6371
const MAX_DISTANCE_CAP = 800
const MAX_ZOOM_LEVEL = 20
const USER_LOCATION_WEIGHTING = 1 / MAX_ZOOM_LEVEL
const SCREEN_CENTER_WEIGHTING = 1 / MAX_ZOOM_LEVEL
const MAX_PLACE_RELEVANCE_SCORE = 1

// Utils
// calculateDistanceInKm - Haversine formula to calculate distance between two latitude/longitude points
export function calculateDistanceInKm(pointA: PlaceType, pointB: ModifiedGeolocation) {
    const deltaLat = toRadians(pointB.lat - pointA.lat)
    const deltaLng = toRadians(pointB.lng - pointA.lng)
    const a =
        Math.sin(deltaLat / 2) ** 2 +
        Math.cos(toRadians(pointA.lat)) * Math.cos(toRadians(pointB.lat)) * Math.sin(deltaLng / 2) ** 2
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    return EARTH_RADIUS * c
}

// toRadians - Convert degrees to radians
const toRadians = (deg: number) => deg * (Math.PI / 180)

// normalizeDistance - Normalize distances to a score between 0 and 1, capping at MAX_DISTANCE
const normalizeDistance = (distance: number) => 1 - Math.min(distance, MAX_DISTANCE_CAP) / MAX_DISTANCE_CAP

// calculateScore - Calculate the score for a place based on relevance, user proximity, and screen center proximity.
// Makes each category out of 1 and then adds them together. The higher the score, the more relevant the place is.
// userDistance and screenCenterDistance are weighted by zoom level. More zoomed in is more importance to both those categories
// and less to relevance
export function calculateScore(
    place: PlaceType,
    userLocation: ModifiedGeolocation | null,
    screenCenter: ModifiedGeolocation,
    zoomLevel: number
) {
    const relevanceWeight = 1.0
    const userDistanceWeight = userLocation ? zoomLevel * USER_LOCATION_WEIGHTING : 0
    const screenCenterDistanceWeight = zoomLevel * SCREEN_CENTER_WEIGHTING

    // Get relevance score
    const relevanceScore = place.relevance_score / MAX_PLACE_RELEVANCE_SCORE

    // Calculate user proximity score if user location is provided
    const userProximityScore = userLocation ? normalizeDistance(calculateDistanceInKm(place, userLocation)) : 0

    // Calculate screen center proximity score
    const screenCenterProximityScore = normalizeDistance(calculateDistanceInKm(place, screenCenter))

    // Compute final score
    return (
        relevanceWeight * relevanceScore +
        userDistanceWeight * userProximityScore +
        screenCenterDistanceWeight * screenCenterProximityScore
    )
}

// getOptionLabel - Get the option label for the Autocomplete component. There are context and second_context fields
// that are used to create the label. If the second_context is empty, it is not shown.
// TODO: data coming from the API doesn't have context either, so this is a temporary solution
export const getOptionLabel = (option: PlaceType) => {
    const { context, second_context, name } = option

    if (!context) return name

    return `${name}, ${context}${second_context ? `, ${second_context}` : ''}`
}

// manageSearchHistory - Adds the new place
export const manageSearchHistory = (
    newPlace: PlaceType,
    oldSearchHistory: PlaceType[],
    setSearchHistory: (searchHistory: PlaceType[]) => void
) => {
    // If that place is already in the history, just move it to the top
    if (oldSearchHistory.some(place => place.fid === newPlace.fid)) {
        const newSearchHistory = [newPlace, ...oldSearchHistory.filter(place => place.fid !== newPlace.fid)]
        setSearchHistory(newSearchHistory)
        return
    }

    // If the history is full, remove the oldest place and add the new one
    if (oldSearchHistory.length >= MAX_SEARCH_HISTORY_LENGTH) {
        const newSearchHistory = [newPlace, ...oldSearchHistory.slice(0, MAX_SEARCH_HISTORY_LENGTH - 1)]
        setSearchHistory(newSearchHistory)
    } else {
        // Otherwise, just add the new place to the history
        setSearchHistory([newPlace, ...oldSearchHistory])
    }

    return
}
