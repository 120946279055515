import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export type Place = {
    fid: number
    name: string
    context: string
    second_context: string
    relevance_score: number
    lat: number
    lng: number
}

export const MAX_SEARCH_HISTORY_LENGTH = 10

type State = {
    searchHistory: Place[]
}

type Action = {
    setSearchHistory: (searchHistory: State['searchHistory']) => void
}

export const SearchHistoryStore = create<State & Action>()(
    persist(
        set => {
            return {
                searchHistory: [],
                setSearchHistory: (searchHistory: State['searchHistory']) => {
                    set({ searchHistory })
                },
            }
        },
        {
            name: 'search-history',
        }
    )
)

export const useSearchHistory = () => SearchHistoryStore(state => state.searchHistory)
export const useSetSearchHistory = () => SearchHistoryStore(state => state.setSearchHistory)
