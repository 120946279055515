import { FormattedMessage } from 'react-intl'

import { IoInformationCircleOutline } from 'react-icons/io5'

import COLOR_PALETTE, { COLOUR_BLIND_PALETTE } from '@avcan/constants/products/forecast/ratings/colors'
import { DANGER_RATINGS_MODAL_OPENED } from '@avcan/constants/products/mixpanel'

import { useUserSettings } from 'contexts/usersettings'
import { useLegendDangerRatings } from 'layouts/map/useDangerRatings'
import { useSendTrackEvent } from 'hooks/useSendTrackEvent'
import { useOpenDangerRatingModal } from 'stores/DangerRatingModalStore'

import css from './Map.module.css'

export const DangerRatingLegend = () => {
    const { colourblindModeEnabled } = useUserSettings()
    const palette = colourblindModeEnabled ? COLOUR_BLIND_PALETTE : COLOR_PALETTE
    const openDangerRatingModal = useOpenDangerRatingModal()

    const ratings = useLegendDangerRatings()
    const sendTrackEvent = useSendTrackEvent()

    const handleClick = () => {
        sendTrackEvent(DANGER_RATINGS_MODAL_OPENED, {})
        openDangerRatingModal()
    }

    return (
        <div style={styles.container}>
            <div style={styles.firstRow}>
                <div style={styles.headingContainer}>
                    <h4 style={styles.heading}>
                        <FormattedMessage defaultMessage="Danger Ratings" description="Danger Ratings heading" />
                    </h4>
                    <div style={styles.explanation}>
                        <FormattedMessage
                            defaultMessage="Click on a region to open a forecast"
                            description="Instructions for map interaction"
                        />
                    </div>
                </div>
                <button className={css.InfoButton} onClick={handleClick} aria-label="info on danger ratings">
                    <IoInformationCircleOutline />
                </button>
            </div>
            <div style={styles.secondRow}>
                {ratings.map(
                    ({ rating, heading, render }) =>
                        render && (
                            <div key={rating} style={styles.item}>
                                <div
                                    style={{
                                        ...styles.swatch,
                                        backgroundColor: palette.get(rating),
                                    }}
                                ></div>
                                <div style={styles.text}>{heading}</div>
                            </div>
                        )
                )}
            </div>
        </div>
    )
}

const styles = {
    container: {
        padding: '3px 0',
        lineHeight: '1.2',
        width: '100%',
        backgroundColor: 'var(--color-blue-dark)',
        color: 'white',
    },
    firstRow: {
        display: 'flex',
        flexWrap: 'wrap' as const,
        alignItems: 'center',
        gap: 8,
    },
    headingContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row' as const,
        alignItems: 'baseline',
        gap: 4,
    },
    secondRow: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap' as const,
    },
    heading: {
        fontSize: '0.85em',
        margin: '0 0.8em',
        textTransform: 'uppercase' as const,
        letterSpacing: '0.08em',
    },
    explanation: {
        fontSize: '0.85em',
        padding: '0.25em 0',
    },
    item: {
        flex: '1 1 0',
        textAlign: 'center' as const,
    },
    swatch: {
        flex: '1 1 0',
        height: 10,
        border: '1px solid #666',
    },
    text: {
        fontSize: '0.85em',
        marginTop: 2,
    },
}
