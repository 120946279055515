import React, { useEffect, useMemo, useRef } from 'react'

import * as Products from '@avcan/constants/products'
import ForecastsClient from '@avcan/utils/api/clients/forecasts'
import { getCoord } from '@turf/invariant'
import { useRouter } from 'next/router'
import { useIntl } from 'react-intl'

import * as Config from 'clients/config'
import { Place } from 'components/icons'
import { PRIMARY } from 'constants/colors'
import { TitleMessages } from 'constants/products/layers'
import * as MapContext from 'contexts/map'
import * as MapState from 'contexts/map/state'
import { STYLES } from 'services/mapbox/config'
import { usePushMapHistory } from 'stores/MixpanelHistoryStore'
import * as ProductUtils from 'utils/product'
import { useMapOffset } from './drawers/hooks'
import * as Layers from './layers'
import * as Sources from './sources/context'
import { zoomToPolygon } from 'utils/map'
import styles from './Map.module.css'

const env = process.env.NODE_ENV

const client = new ForecastsClient(Config.api, Config.key)

export default function Map({ children }) {
    const intl = useIntl()

    const { query, locale, asPath, push } = useRouter()
    const { lat, lng } = query || {}

    const { productId, setProductId, openPrimaryDrawer, zoom, center, errors } = MapState.useMapState()
    const pushMapHistory = usePushMapHistory()

    const options = useRef()
    const offset = useMapOffset()
    const areas = Sources.useForecastAreas()

    // Set the product via an API call rather than battling async issues (like waiting for the map to load then clicking on a layer)
    useEffect(() => {
        if (lat && lng) {
            client.point({ lat, lng }, locale).then(response => {
                if (!response.id) return

                setProductId(response.id)
            })
        }
    }, [lat, lng, locale, setProductId])

    options.current = MapState.useOptions(options)

    const once = useMemo(
        () => ({
            async load(event) {
                const { target: map } = event

                // For debugging in dev. Use the map object in the browser's console.
                if (env === 'development') {
                    window.map = map
                }

                if (lng && lat) {
                    try {
                        // Zoom to the point on page load. Note that this is slightly different than zooming to the corresponding forecast’s polygon bounds (got into nasty async issues)
                        map.flyTo({
                            center: [lng, lat],
                            zoom: 6,
                        })
                    } catch (error) {
                        // We do not need to do anything with that!
                        // Required to avoid "onunhandledrejection" error!
                    }
                }
            },
        }),
        []
    )

    const on = useMemo(
        () => ({
            zoomend(event) {
                const zoomValue = event.target.getZoom()
                const centerValue = event.target.getCenter()

                let eventType = 'forecast click'
                if (event.geolocateSource) {
                    eventType = event.geolocateSource
                }
                const originalEvent = event.originalEvent
                if (originalEvent?.type) {
                    eventType = originalEvent.type
                }

                pushMapHistory({
                    action: 'zoom',
                    zoom: zoomValue,
                    center: centerValue,
                    eventType,
                    timestamp: Date.now(),
                })

                zoom.set(zoomValue)
            },
            moveend(event) {
                const zoomValue = event.target.getZoom()
                const centerValue = event.target.getCenter()

                let eventType = 'forecast click'
                if (event.geolocateSource) {
                    eventType = event.geolocateSource
                }
                const originalEvent = event.originalEvent
                if (originalEvent?.type) {
                    eventType = originalEvent.type
                }

                pushMapHistory({
                    action: 'move',
                    center: centerValue,
                    zoom: zoomValue,
                    eventType,
                    timestamp: Date.now(),
                })

                center.set(centerValue)
            },
            error(event) {
                errors.add(MapState.ERRORS.MAP, event.error)
            },
        }),
        []
    )

    const onLayerEvents = useMemo(() => {
        let counter = 0

        return {
            mouseenter({ target }) {
                const canvas = target.getCanvas()

                canvas.style.cursor = 'pointer'
                counter++
            },
            mouseleave({ target }) {
                const canvas = target.getCanvas()

                counter--

                if (counter < 1) {
                    canvas.style.cursor = ''
                }

                canvas.title = ''
            },
            mousemove(event) {
                // "mousemove" is the best way to handle title!
                // "mouseenter" does not work as well as "mousemove"
                const canvas = event.target.getCanvas()
                const [{ properties }] = event.features
                let title = properties.name || properties.title || ''

                if (properties.cluster) {
                    const { product, point_count } = properties
                    const label = intl.formatMessage(TitleMessages[product])

                    title = point_count + ' ' + label
                }

                canvas.title = title
            },
            click(event) {
                const { point, lngLat, target: map } = event
                const features = map.queryRenderedFeatures(point)

                if (features.length === 0) {
                    return
                }
                const [feature] = features
                const { properties } = feature

                if (properties.cluster) {
                    const source = map.getSource(feature.source)

                    source.getClusterExpansionZoom(properties.cluster_id, (error, zoom) => {
                        if (error) {
                            // We do not really care if there is an error,
                            // we will just zoom in a bit so user receives a
                            // feedback to the click on the cluster!
                            zoom = map.getZoom() + 1
                        }

                        map.flyTo({
                            center: getCoord(feature),
                            zoom,
                            offset,
                        })
                    })
                } else {
                    if ('url' in properties) {
                        const { url, id } = properties

                        window.open(url, id)
                    } else {
                        const { product, id } = properties
                        const url = new URL(asPath, window.location.origin)
                        const path = ProductUtils.createPath(product, id)

                        if (ProductUtils.isAnalysis(product)) {
                            url.search = generateLatLngQuery(url, lngLat)
                            setProductId(id)
                        } else if (product === Products.SPAW) {
                            url.search = generateLatLngQuery(url, lngLat)
                            const forecastFeature = features.find(feature => feature.layer.id === 'forecast')
                            if (forecastFeature) {
                                const { properties: underlyingForecastProperties } = forecastFeature
                                setProductId(underlyingForecastProperties.id)
                                const polygon = areas?.features.find(area => {
                                    return area.properties.id === underlyingForecastProperties.id
                                })
                                zoomToPolygon(map, polygon)
                            }
                        } else if (product === 'weather-station') {
                            url.pathname = window.location.pathname
                            url.searchParams.set('panel', path)
                            url.searchParams.delete('lat')
                            url.searchParams.delete('lng')
                        } else {
                            url.searchParams.set('panel', path)
                            url.searchParams.delete('lat')
                            url.searchParams.delete('lng')
                        }

                        push(url.toString(), undefined, {
                            shallow: true,
                        })

                        const polygon = areas?.features.find(area => {
                            return area.properties.id === feature.id
                        })

                        zoomToPolygon(map, polygon)
                    }
                }
            },
        }
    }, [intl])

    return (
        <div className={styles.Map} data-test="mainMap">
            <MapContext.Map options={options.current} style={STYLES.default} on={on} once={once}>
                <MapContext.NavigationControl />
                <MapContext.GeolocateControl options={GEOLOCATE_CONTROL_OPTIONS} />
                <MapContext.WithMapReady>
                    <Layers.ForecastAreas on={onLayerEvents} id={productId} />
                    <Layers.ForecastMarkers />
                    <Layers.ClosureZones />
                    <Layers.SPAW on={onLayerEvents} />
                    <Layers.WeatherStations on={onLayerEvents} />
                    <Layers.MountainConditionReports on={onLayerEvents} />
                    <Layers.MountainInformationNetwork on={onLayerEvents} />
                    <Layers.IceClimbing on={onLayerEvents} />
                    <Layers.FatalAccidents on={onLayerEvents} />
                </MapContext.WithMapReady>
                {children}
                {lat && lng && (
                    <MapContext.Marker
                        lnglat={{
                            lng,
                            lat,
                        }}
                    >
                        <Place width={40} height={40} color={PRIMARY} />
                    </MapContext.Marker>
                )}
            </MapContext.Map>
        </div>
    )
}

// Helpers and Constants
const GEOLOCATE_CONTROL_OPTIONS = {
    fitBoundsOptions: {
        maxZoom: 10,
    },
}

const generateLatLngQuery = (url, { lat, lng }) => {
    if (lat) {
        url.searchParams.set('lat', lat.toFixed(6))
    }
    if (lng) {
        url.searchParams.set('lng', lng.toFixed(6))
    }

    return url.search
}
