import { useCallback, useMemo, useState } from 'react'

import { Autocomplete, Box } from '@mui/material'
import { Polygon } from 'geojson'
import { LngLatLike, Map } from 'mapbox-gl'
import { useRouter } from 'next/router'
import { useMediaQuery } from 'react-responsive'

import { PLACENAME_SEARCH } from '@avcan/constants/products/mixpanel'

import { GeocoderTextField } from 'components/controls/MapGeocoder/GeocoderTextField'
import localPlaces from 'components/controls/MapGeocoder/localPlaces.json'
import { Option } from 'components/controls/MapGeocoder/Option'
import { calculateScore, manageSearchHistory, ModifiedGeolocation } from 'components/controls/MapGeocoder/utils'
import Place from 'components/icons/Place'
import PRIMARY from 'constants/colors'
import { MOBILE_PIXEL_WIDTH } from 'constants/general'
import { Marker, useMap } from 'contexts/map'
import { useMapState } from 'contexts/map/state'
import { useForecastAreas } from 'hooks/useForecasts'
import { useGeocoder } from 'hooks/useGeocoder'
import { useSendTrackEvent } from 'hooks/useSendTrackEvent'
import { fetchForecastForPoint } from 'services/api/Forecasts'
import { Place as PlaceType, useSearchHistory, useSetSearchHistory } from 'stores/SearchHistoryStore'
import { zoomToPolygon } from 'utils/map'

import css from './MapGeocoder.module.css'

const MAX_RESULTS = 20
const WIDTH = 300

const MISSING_PLACE = {
    fid: -1,
    name: 'Add a missing place',
    context: '',
    second_context: '',
    relevance_score: 0,
    lat: 0,
    lng: 0,
}

export const Geocoder = () => {
    const router = useRouter()
    const isMobile = useMediaQuery({ maxWidth: MOBILE_PIXEL_WIDTH })

    const { center, zoom } = useMapState()
    const map = useMap() as Map | null
    const sendTrackEvent = useSendTrackEvent()

    const searchHistory = useSearchHistory()
    const setSearchHistory = useSetSearchHistory()

    const [value, setValue] = useState<PlaceType | null>(null)
    const [inputValue, setInputValue] = useState('')
    const [geolocation, setGeolocation] = useState<ModifiedGeolocation | null>(null)
    const [lngLat, setLngLat] = useState<ModifiedGeolocation | null>(null)

    const { data: results } = useGeocoder(inputValue)
    const { data: areas } = useForecastAreas()

    const handleFocus = () => {
        if ('geolocation' in navigator && geolocation === null) {
            navigator.geolocation.getCurrentPosition(position => {
                setGeolocation({ lat: position.coords.latitude, lng: position.coords.longitude })
            })
        }
    }

    const handleOptionSelect = async (place: PlaceType | null) => {
        setValue(place)

        if (place && map) {
            manageSearchHistory(place, searchHistory, setSearchHistory)

            const forecast = await fetchForecastForPoint(place.lat, place.lng)
            const area = areas?.features.find(area => area.id === forecast.data.area.id)

            if (area && !isMobile) {
                zoomToPolygon(map, area as unknown as Polygon)
            } else {
                const flyToLocation =
                    zoom.value > 5
                        ? { center: [place.lng, place.lat] as LngLatLike, zoom: 5 }
                        : { center: [place.lng, place.lat] as LngLatLike }
                map.flyTo(flyToLocation)
            }

            router.push({
                query: {
                    lat: place.lat,
                    lng: place.lng,
                },
            })

            setLngLat({ lng: place.lng, lat: place.lat })

            sendTrackEvent(PLACENAME_SEARCH, {
                place_name: place.name,
                typed_place_name: inputValue,
                place_id: place.fid,
                forecast: forecast,
            })
        }
    }

    const relevant20Places = useMemo(() => {
        if (!center.value?.lat || !center.value?.lng || !zoom.value) {
            const filteredLocalPlaces = localPlaces
                .sort((a, b) => b.relevance_score - a.relevance_score)
                .slice(0, MAX_RESULTS)
                .filter(place => !searchHistory.some(searchPlace => searchPlace.fid === place.fid))

            return [...searchHistory, ...filteredLocalPlaces]
        }

        const userLocation = geolocation ? geolocation : null

        const filteredLocalPlaces = localPlaces
            .sort(
                (a, b) =>
                    calculateScore(b as PlaceType, userLocation, center.value, zoom.value) -
                    calculateScore(a as PlaceType, userLocation, center.value, zoom.value)
            )
            .slice(0, MAX_RESULTS)
            // Filter out places that are already in the search history so we don't have duplicates
            .filter(place => !searchHistory.some(searchPlace => searchPlace.fid === place.fid))

        return [...searchHistory, ...filteredLocalPlaces]
    }, [center.value, zoom.value, geolocation, searchHistory])

    const filterOptions = useCallback(
        (options: PlaceType[]) => {
            if (!inputValue) return [...options, MISSING_PLACE]

            const filteredOptions = options
                // Check if they are in the search history. If they are, move them to the top
                .sort((a, b) => {
                    const aIsInSearchHistory = searchHistory.some(searchPlace => searchPlace.fid === a.fid)
                    const bIsInSearchHistory = searchHistory.some(searchPlace => searchPlace.fid === b.fid)

                    if (aIsInSearchHistory && !bIsInSearchHistory) return -1
                    if (!aIsInSearchHistory && bIsInSearchHistory) return 1
                    return 0
                })

            const finalOptions = [...filteredOptions, MISSING_PLACE]

            return finalOptions
        },
        [inputValue, searchHistory]
    )

    // TODO: the placeholder text should be black, not grey for accessibility

    return (
        <Box className={css.Container}>
            <Autocomplete
                sx={styles.autocomplete}
                value={value}
                onChange={(e, newValue) => handleOptionSelect(newValue)}
                inputValue={inputValue}
                onInputChange={(_event, newInputValue) => {
                    setInputValue(newInputValue)
                }}
                onFocus={handleFocus}
                blurOnSelect
                options={inputValue ? (results as PlaceType[]) : (relevant20Places as PlaceType[])}
                getOptionLabel={option => option.name}
                filterOptions={filterOptions}
                renderInput={params => <GeocoderTextField {...params} />}
                renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option) => {
                    return <Option props={props} option={option} />
                }}
                componentsProps={styles.componentProps}
            />
            {lngLat && (
                <Marker lnglat={lngLat}>
                    <Place color={PRIMARY} />
                </Marker>
            )}
        </Box>
    )
}

const styles = {
    autocomplete: {
        width: WIDTH,
        '& .MuiInputBase-root': {
            height: '42px',
            transform: 'translateY(-25%)',
        },
        '& .MuiAutocomplete-input': {
            padding: '2px 4px !important',
        },
        '& .MuiAutocomplete-endAdornment': {
            top: '40%',
            right: '8px',
        },
        '& .MuiAutocomplete-clearIndicator': {
            padding: '2px',
        },
    },
    componentProps: {
        popper: {
            style: {
                width: `${WIDTH}px`,
                paddingTop: '20px',
            },
        },
        paper: {
            style: {
                borderRadius: '16px',
            },
        },
    },
}
