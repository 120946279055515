import useSWR from 'swr'

import { fetchAreas, fetchForecastForPoint } from 'services/api/Forecasts'
import { FeatureCollection } from '@turf/helpers'

const FORECAST_AREAS_KEY = 'forecast-areas'
const FORECAST_POINT_KEY = 'forecast-for-point'
// TODO: These duplicates some logic with the old way to fetch areas. Eventually we'd like to move over to this approach

export const useForecastAreas = () => {
    const { data, error } = useSWR(FORECAST_AREAS_KEY, () => fetchAreas())

    return { data: data?.data as FeatureCollection, error }
}

export const useForecastForPoint = (lat: number | undefined, lng: number | undefined, language: string | undefined) => {
    const key = [FORECAST_POINT_KEY, lat, lng, language]

    const { data, error } = useSWR(lat && lng ? key : null, () =>
        fetchForecastForPoint(lat as number, lng as number, language)
    )

    return { data, error }
}
