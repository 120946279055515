import CloseIcon from '@mui/icons-material/Close'
import History from '@mui/icons-material/History'
import { Box, Button, IconButton } from '@mui/material'
import { useIntl } from 'react-intl'

import { Place, useSearchHistory, useSetSearchHistory } from 'stores/SearchHistoryStore'
import { getOptionLabel } from './utils'

import css from './MapGeocoder.module.css'

type OptionProps = {
    props: React.HTMLAttributes<HTMLLIElement>
    option: Place
}

export const Option = ({ props: { className, ...restProps }, option }: OptionProps) => {
    const searchHistory = useSearchHistory()
    const setSearchHistory = useSetSearchHistory()

    const isSearchHistory = searchHistory.some(searchHistoryPlace => searchHistoryPlace.fid === option.fid)
    const isLastSearchHistory = searchHistory.length > 0 && searchHistory[searchHistory.length - 1].fid === option.fid

    const handleDelete = (e: React.MouseEvent<HTMLButtonElement>, option: Place) => {
        e.stopPropagation()

        setSearchHistory(searchHistory.filter(place => place.fid !== option.fid))
    }

    if (option.fid === -1) return <MissingPlaceOption />

    return (
        <>
            <Box component="li" sx={styles.container} {...restProps}>
                <Box sx={styles.optionContainer}>
                    {isSearchHistory && (
                        <Box style={styles.historyIcon}>
                            <History fontSize="small" />
                        </Box>
                    )}
                    <Box
                        key={option.fid}
                        className={isSearchHistory ? `${css.SearchHistory} ${className}` : className}
                        style={{ paddingLeft: isSearchHistory ? '6px' : '32px' }}
                    >
                        {getOptionLabel(option)}
                    </Box>
                </Box>
                {isSearchHistory && (
                    <IconButton size="small" onClick={e => handleDelete(e, option)} sx={styles.deleteButton}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                )}
            </Box>
            {isLastSearchHistory && <div style={styles.searchHistoryBar} />}
        </>
    )
}

export const MissingPlaceOption = () => {
    const intl = useIntl()

    return (
        <Box sx={styles.missingPlaceButton}>
            <Button
                href="https://docs.google.com/forms/d/e/1FAIpQLSdG6AoT1Qfygz2eOIf8_oLpGQKtIy4BzCvX-RRlZ9peIDCitw/viewform"
                target="_blank"
                rel="noopener noreferrer"
                fullWidth
            >
                {intl.formatMessage({
                    description: 'Geocoder',
                    defaultMessage: 'Add a missing place',
                })}
            </Button>
        </Box>
    )
}

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        '&:hover': {
            backgroundColor: 'action.hover',
        },
        cursor: 'pointer',
    },
    optionContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    historyIcon: {
        color: '#666',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '6px',
    },
    deleteButton: {
        marginLeft: '8px',
        width: 32,
        height: 32,
        alignSelf: 'center',
    },
    searchHistoryBar: {
        borderBottom: '1px solid #CCCCCC',
        width: '100%',
    },
    missingPlaceButton: {
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: 'action.hover',
        },
    },
}
