import React from 'react'

import * as Env from 'env'

import { supported } from 'utils/mapbox'
import { UnsupportedMap, Screen } from 'layouts/pages'
import { Menu, ToggleMenu, Primary, Secondary } from './drawers'
import { Provider as MenuProvider } from 'contexts/menu'
import { Provider as LayersProvider } from 'contexts/layers'
import { Provider as MapStateProvider } from 'contexts/map/state'
import MapLayout from './map'
import { Geocoder } from 'components/controls/MapGeocoder/Geocoder'
import { FooterDragHandle } from 'layouts/pages'
import { LinkControlSet } from './LinkControlSet'
import { DangerRatingLegend } from './DangerRatingLegend'

import './Map.module.css'

export default function Layout() {
    if (Env.server) {
        return <Screen />
    }

    if (!supported()) {
        return <UnsupportedMap />
    }

    return (
        <Screen footer={<FooterDragHandle />}>
            <LayersProvider>
                <MenuProvider>
                    <MapStateProvider>
                        <MapLayout>
                            <Primary />
                            <Secondary />
                            <Menu />
                            <ToggleMenu />
                            <Geocoder />
                            <LinkControlSet />
                        </MapLayout>
                        <DangerRatingLegend />
                    </MapStateProvider>
                </MenuProvider>
            </LayersProvider>
        </Screen>
    )
}
