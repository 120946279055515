import { useMemo } from 'react'

import useSWR from 'swr'

import { Place } from 'stores/SearchHistoryStore'
import { BASE_KEY, fetchResults } from 'services/api/Geocoder'

export type GeocoderResponse = {
    id: string
    context: string
    latitude: number
    longitude: number
    name: string
    relevance_score: number
    second_context: string
}

export const useGeocoder = (term: string) => {
    const key = [BASE_KEY, term]

    const { data } = useSWR(term ? key : null, () => fetchResults(term))

    const mappedData: Place[] = useMemo(() => {
        if (!data || !data.data) return []

        return data.data.map((item: GeocoderResponse) => ({
            fid: item.id,
            context: item.context,
            lat: item.latitude,
            lng: item.longitude,
            name: item.name,
            relevance_score: item.relevance_score,
            second_context: item.second_context,
        }))
    }, [data])

    return { data: mappedData }
}
