import { LngLatBoundsLike, Map } from 'mapbox-gl'
import { Polygon } from 'geojson'

export const zoomToPolygon = (map: Map, polygon: Polygon) => {
    if (!polygon) {
        return null
    }
    map.fitBounds(polygon.bbox as LngLatBoundsLike, {
        padding: {
            top: 50,
            bottom: 50,
            left: 50,
            // Large right padding to offset the map when the right drawer is open.
            // As a side effect, this also prevents zoom-to-fit on mobile, which is desirable as the drawer completely covers the map once opened.
            right: 500,
        },
    })
}
