import { APIClient } from 'services/api/APIClient'

export const BASE_KEY = '/forecasts'

export const fetchAreas = () => {
    return APIClient.get(`${BASE_KEY}/en/areas`)
}

export const fetchForecastForPoint = (lat: number, lng: number, language = 'en') => {
    return APIClient.get(`${BASE_KEY}/${language}/products/point?lat=${lat}&long=${lng}`)
}
