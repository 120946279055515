import axios from 'axios'

export const GeoCoderClient = axios.create({
    baseURL: process.env.NEXT_PUBLIC_GEOCODER_API_URL as string,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-API-Key': process.env.NEXT_PUBLIC_GEOCODER_API_KEY as string,
    },
})
